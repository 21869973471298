<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    @close=close
    width="708px"
    title="添加日程">
    <el-form
      class="t-form"
      label-width="80px"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="选择日期">
        <el-col :span="10">
          <el-date-picker
            v-model="params.nowDate"
            :clearable="false"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-col>
        <el-col :span="14" v-if="params.nowDate.length > 0">
          <el-checkbox-group v-model="dateType">
            <el-checkbox label="1">全天</el-checkbox>
            <el-checkbox label="2">重复</el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-form-item>
      <el-form-item label="">
        <div v-if="dateType.includes('2')" class="repeat-day-select">
          <el-checkbox-group v-model="afterDayList" @change="addDate">
            <el-checkbox v-for="date in followDays" :label=date.value :key="'follow_day_'+date.value">{{date.label}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="repeat" @change="repeatDate">
            <el-checkbox label="30">往后30天</el-checkbox>
            <el-checkbox label="60">往后60天</el-checkbox>
            <el-checkbox label="120">往后120天</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <div>
        <div
          v-for="(v, i) in params.schedules"
          :key="i"
          class="form-item-box">
		  <div class="flex jc_b item-box-title">
			  <span>日程{{numberToChanie(i+1)}}</span>
			  <i
				@click="remove(i)"
				v-if="params.schedules && params.schedules.length > 1"
				class="del-icon el-icon el-icon-close pointer not-select fs14"/>
		  </div>
          <div class="double-cell">			  
            <el-form-item label="选择时间" class="mr52">
              <div class="flex ai_c">
                <el-time-picker
                  v-model="v.time"
                  @change="selectTime(1,v.time)"
                  :picker-options="{
                    selectableRange: `00:00:00 - ${v.endTime ? v.endTime + ':00' : '23:59:59'}`
                  }"
                  style="width: 100%;"
                  placeholder="开始时间"
                  value-format="HH:mm"
                  format="HH:mm"/>
                <span class="ml10 mr10">-</span>
                <el-time-picker
                  v-model="v.endTime"
                  @change="selectTime(2,v.time)"
                  :picker-options="{
                    selectableRange: `${v.time ? v.time + ':59' : '00:00:00'} - 23:59:59`
                  }"
                  style="width: 100%;"
                  placeholder="结束时间"
                  value-format="HH:mm"
                  format="HH:mm"/>
              </div>
            </el-form-item>
            <el-form-item label="经办人">
              <el-select
                v-model="v.userIds"
                placeholder="请选择经办人"
                multiple>
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId" />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="计划内容">
            <el-input v-model="v.content" type="textarea" rows="6" placeholder="请输入计划内容" resize="none" />
          </el-form-item>
          <el-form-item label="地点">
            <el-input v-model="v.place" placeholder="请输入地点（非必填）" />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="flex jc_b mt24">
		<div
		  v-if="!allDay"
		  @click="addDay"
		  class="add-green fs14 pointer not-select">
		  <img src="~@/assets/image/global/icon_add_green.png" class="mr5" width="12" height="12" />
		  新增日程
		</div>
		<div class="flex jc_e ai_c">
		  <t-btn 
			w="78px"
			h="36px"
			fs="14px"
			type="info"
			@click="visible = false" 
			class="bold">取消</t-btn>
		  <t-btn
		    @click="commit"
		    w="78px"
		    h="36px"
		    class="bold ml10">确认</t-btn>
		</div>
	</div>	
  </el-dialog>
</template>

<script>
import message from '@/mixins/message'

const initDay = () => ({
  time: '',
  endTime: '',
  place: '',
  content: '',
  userIds: []
})
const initParams = () => ({
  nowDate: "",
  schedules: [initDay()]
})
export default {
  mixins: [message],
  props: {
    userList: {
      type: Array,
      value: () => []
    }
  },
  data() {
    return {
      visible: false,
      allDay: false,    //是否全天
      dateType: [],
      afterDayList: [],
      repeat : [],
      params: initParams()
    }
  },
  computed: {
    followDays(){
      let arr = [];
      let nowDate = new Date(this.params.nowDate);
      for( let i = 0 ; i < 7 ; i ++ ){
        let time = nowDate.setDate(nowDate.getDate()+1)
        time = new Date(time);
        let year = time.getFullYear();
        let month = time.getMonth()+1;
        let day = time.getDate()
        if( String(month).length < 2 ) month = '0' + month;
        if( String(day).length < 2 ) day = '0' + day;
        arr.push({
          label : `${month}.${day}`,
          value : `${year}-${month}-${day}`
        })
      }
      return arr
    }
  },
  watch: {
    dateType(val){
      if( val.includes("1") ){
        let schedules = this.params.schedules;
        this.params.schedules = [Object.assign(schedules[0],{
          time: "00:00",
          endTime: "23:59"
        })]
        this.allDay = true;
      }else{
        this.allDay = false;
      }
    }
  },
  methods: {
	numberToChanie(num){
		let chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
		let chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
		let chnUnitChar = ["", "十", "百", "千"];
		let strIns = '', chnStr = '';
		let unitPos = 0;
		let zero = true;
		 while (num > 0) {
		    let v = num % 10;
		    if (v === 0) {
		      if (!zero) {
		        zero = true;
		        chnStr = chnNumChar[v] + chnStr;
		      }
		    } else {
		      zero = false;
		      strIns = chnNumChar[v];
		      strIns += chnUnitChar[unitPos];
		      chnStr = strIns + chnStr;
		    }
		    unitPos++;
		    num = Math.floor(num / 10);
		}
		return chnStr;
	},
    // 确认
    commit() {
      const { params,afterDayList } = this
      let msg = ''

      params.date = [params.nowDate,...this.afterDayList];

      if( this.repeat.length > 0 ){
        params.repeat = this.repeat[0]-0
      }else{
        delete params.repeat
      }

      if (!params.date) {
        msg = '请选择日程日期'
      } else if (!params.schedules.every(v => v.time)) {
        msg = '请选择完整各项日程的开始时间'
      } else if (!params.schedules.every(v => v.endTime)) {
        msg = '请选择完整各项日程的结束时间'
      } else if (!params.schedules.every(v => v.userIds.length)) {
        msg = '请选择完整各项日程的参与人'
      } else if (!params.schedules.every(v => v.content)) {
        msg = '请填写完整各项日程的内容'
      } else {
        msg = ''
      }

      if (msg) {
        this.error({
          message: msg
        })
        return
      }
      this.$emit('commit', params)
    },
    // 显示
    show(date) {
      this.visible = true
      if( date && typeof date == "string" && date.length > 0 ){
        this.$set(this.params,"nowDate",date)
      }
    },
    close() {
      this.visible = false
      this.params.date = "";
      this.dateType = [];
      this.afterDayList = [];
      this.empty();
    },
    addDay() {
      this.params.schedules = this.params.schedules.concat(initDay())
    },
    remove(index) {
      this.params.schedules = this.params.schedules.filter((v, i) => i !== index)
    },
    empty() {
      this.params = initParams()
      this.allDay = false    //是否全天
      this.dateType = []
      this.afterDayList = []
      this.repeat = []
    },
    selectTime(type,time) {
      let times = ["00:00","23:59"];
      let index = this.dateType.indexOf("1");
      if( index >= 0 ){
        if( time !== times[type-1] ){
          this.dateType.splice(index,1)
        }
      }else{
        let schedules = this.params.schedules[0];
        if( schedules.time == "00:00" && schedules.endTime == "23:59" ){
          this.dateType.push("1")
        }
      }
    },
    addDate(){
      this.repeat = [];
    },
    repeatDate(){
      this.repeat = [this.repeat.pop()]
      this.afterDayList = [];
    }
  }
}
</script>

<style lang="scss" scoped>
	.add-green{
		width: 98px;
		height: 36px;
		line-height: 36px;
		background: rgba(64,185,164,0.06);
		border-radius: 8px;
		border: 1px solid rgba(64,185,164,0.15);
		color: #40B9A4;
		text-align: center;
		font-size: 14px;
	}
	.form-item-box {
		position: relative;
		padding: 16px 16px 4px;
		background: #F7FAFB;
		border-radius: 8px;
		overflow: hidden;
		.item-box-title{
			margin-bottom: 10px;
			span{
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #9AAAB7;
				line-height: 18px;
			}
			.del-icon{
				color: #60707D;
			}
		}
		.el-form .el-form-item {
			margin-bottom: 12px;
		}
		&:not(:last-child) {
		  margin-bottom: 16px;
		}
	}
</style>
