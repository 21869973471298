<template>
    <div class="business-container">
        <div class="business-main">
            <tabHeader :tabs="tabs" @tabClick=tabClick @trigger=apply @myView=showMyView>
                <template v-slot:search>
                    <el-select clearable v-model="searchForm.regionId" class="search-region" placeholder="请选择查询区域">
                        <el-option v-for="item in regions.data" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="searchForm.type" placeholder="请选择协作类型" class="search-type">
                        <el-option v-for="(item, index) in types" :key="'type_' + index" :label="item" :value="index + 1">
                        </el-option>
                    </el-select>
                </template>
            </tabHeader>
            <div v-if="activeIndex == 0" class="recommend-header">
                <span class="active">按发布时间</span>
            </div>
            <!-- <el-divider></el-divider> -->
            <div class="tab-mine" v-if="activeIndex == 1">
                <el-radio-group v-model="myTaskTab">
                    <el-radio-button :label=1>我的发布</el-radio-button>
                    <el-radio-button :label=2>我的接单</el-radio-button>
                </el-radio-group>
            </div>
            <div class="list-content" v-if="list.length">
                <list :data=list :myTaskTab=myTaskTab @cardClick=cardClick @cancel=cancel>
                </list>
                <detail ref="detail" @afterConfirm=page></detail>
                <my-task ref="task" :type=myTaskTab @afterConfirm=page></my-task>
                <el-pagination class="pagination" layout="total, sizes, prev, pager, next, jumper"
                    :current-page="pages.currentPage" :page-size="pages.perPage" :total="pages.totalCount"
                    :page-sizes="pageSizes" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
            </div>
            <div v-show="!list || !list.length" class="nothing-box" style="margin-top: 100px;">
                <i class="not-icon" />
                <p class="not-tit">暂无内容</p>
            </div>


        </div>
        <apply ref="apply" @applyDone=page></apply>
    </div>
</template>

<script>

import { mapMutations } from 'vuex'
import message from '@/mixins/message'
import tabHeader from "@/components/layout/components/tab-layout.vue"
import list from './components/list-item.vue'
import apply from './components/apply.vue'
import detail from './components/detail.vue'
import MyTask from './components/my-task.vue'

export default {
    name: "coop",
    mixins: [message],
    data() {
        return {
            pageSizes: [15, 20, 30, 40, 50, 100],
            tabs: ["任务", "我的"], //,"消息"
            activeIndex: 0,        //展示列表
            myTaskTab: 0,               // 1我的发布  2我的接单
            forbidAuths: [],           //该用户禁止权限
            types: ["辩护/代理", "会见", "调查取证", "阅卷", "异地查档", "其他"],
            list: [],
            searchForm: {
                regionId: ""
            },
            regions: {
                data: []
            },
            pageConfig: {
                perPage: 12,
                page: 1
            }
        }
    },
    provide() {
        return {
            REGIONS: this.regions
        }
    },
    components: {
        tabHeader,
        list,
        apply,
        detail,
        MyTask
    },
    watch: {
        activeIndex(val) {
            if (val == 0) {
                this.myTaskTab = 0;
                this.page();
            } else if (val == 1) {
                this.myTaskTab = 1;
            }
        },
        myTaskTab(val) {
            if (val > 0) this.toMyTask(val)
        },
        searchForm: {
            handler() {
                this.page();
            },
            deep: true
        }
    },
    mounted() {
        this.$api.system.forbidAuth().then(res => {
            this.forbidAuths = res.forbidAuths;
        })
        this.getCommon();
        this.page();
    },
    methods: {

        handleSizeChange(val) {
            this.pageConfig.perPage = val;
          
                this.page();
          

        },
        handleCurrentChange(val) {
            this.pageConfig.page = val;
          
                this.page();
          
        },

        ...mapMutations(['SETMSGDIALOG']),
        getCommon() {
            this.$api.system.getGlobalList({ type: "regions" }).then(res => {
                let regions = res.regions;
                const deleteEmpty = function (arr) {
                    arr.forEach(a => {
                        if (a.children) {
                            if (a.children.length == 0) {
                                delete a.children;
                            } else {
                                deleteEmpty(a.children)
                            }
                        }
                    })
                }
                deleteEmpty(regions)
                this.regions.data = regions;
            })
        },
        page() {
            this.list = [];
            const api = ["list", "myIssue", "receive"],
                { pageConfig, searchForm: form, myTaskTab } = this;
            this.$api.coop[api[myTaskTab]]({
                ...pageConfig,
                ...form
            }).then(res => {
                this.list = res.items
                this.pages = res.page
            })
        },
        showMyView() {
        },
        tabClick(index) {
            this.activeIndex = index
            // this.myTaskTab = 1;
        },
        cardClick(type, info) {
            if (this.forbidAuths.includes(2)) {
                this.error({
                    message: '该账号禁止接单案件'
                })
            } else {
                let api = "";
                if (type == 0) {
                    api = info.isSelf ? "detailBySelf" : "detailNotSelf";
                } else {
                    api = this.myTaskTab == 1 ? "detailBySelf" : "receiveDetail";
                }
                let { area, type: typeName, status, createAt } = info
                this.$api.coop[api]({ id: info.id }).then(data => {
                    if (type == 0) {
                        this.$refs.detail.show(Object.assign({}, data, { area, typeName, status, createAt }));
                    } else {
                        this.$refs.task.show(Object.assign({}, data, { area, typeName, status, createAt }), this.myTaskTab == 1);
                    }
                })
            }
        },
        cancel({ id }) {
            if (this.myTaskTab == 1) {
                this.SETMSGDIALOG({
                    title: '撤销',
                    message: '是否撤销该任务',
                    width: "439px",
                    type: "error",
                    commit: () => {
                        this.$api.coop.cancel({
                            id
                        }).then(() => {
                            this.success({
                                message: '撤销任务成功',
                                duration: 1000
                            })
                            this.page()
                            this.SETMSGDIALOG(null)
                        })
                    }
                })
            } else if (this.myTaskTab == 2) {
                this.SETMSGDIALOG({
                    title: '撤销',
                    message: '是否撤销该接单',
                    width: "439px",
                    type: "error",
                    commit: () => {
                        this.$api.coop.cancelApply({
                            id
                        }).then(() => {
                            this.success({
                                message: '撤销接单成功',
                                duration: 1000
                            })
                            this.page()
                            this.SETMSGDIALOG(null)
                        })
                    }
                })
            }

        },
        toMyTask(index) {
            this.myTaskTab = index;
            this.page();
        },
        apply() {
            if (this.forbidAuths.includes(1)) {
                this.error({
                    message: '该账号禁止发起案件'
                })
            } else {
                this.$refs.apply.show();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.business-container {
    height: 100%;
    background: #fff;
    padding: 24px;
    border-radius: 16px;

    .recommend-header {
        margin-bottom: 20px;

        span {
            display: inline-block;
            width: 90px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #C3CFD9;
            color: #9AAAB7;
            border-radius: 8px;
            text-align: center;
            font-size: 14px;

            &.active {
                background: rgba(5, 107, 253, 0.1);
                color: #056BFD;
            }
        }
    }

    .search-region,
    .search-type {
        margin-right: 8px;
        width: 220px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EFF1F3;

        .el-input__inner {
            height: 36px;
            line-height: 36px;
        }
    }

    .el-divider {
        margin: 8px 0 15px;
    }

    .business-main {
        height: 100%;
    }

    .list-content {
        height: calc(100% - 104px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }

        p {
            text-align: center;
            cursor: pointer;
        }

        .pagination {
            float: right;
        }
    }

    .tab-mine {
        margin-bottom: 20px;

        /deep/ .el-radio-button__orig-radio+.el-radio-button__inner {
            background: rgba(195, 207, 217, 0.2);
            border-radius: 8px;
            padding: 3px 17px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #9AAAB7;
            line-height: 20px;
            border-color: #f3f5f7;
            margin-right: 6px;
        }

        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            color: #056BFD;
            background: rgba(5, 107, 253, 0.1);
            border-color: #e6f0ff;
            box-shadow: none;
        }
    }

    ::v-deep .el-dialog__header {
        padding: 22px 24px 0;
        min-height: 46px;
        font-weight: 500;
        color: #111111;
        font-size: 16px;
    }

    ::v-deep .el-dialog__body {
        padding: 16px 24px 24px;
    }
}
</style>