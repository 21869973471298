<template>
    <div class="task-card-container">
        <div 
            v-for="(item,index) in data"
            :key="'task_'+index"
            class="task-card-item">
            <div class="task-card-content">
				
				<div class="card-title flex jc_b">
					<div class="left flex pointer"  @click="triggerClick(item)">
						{{item.area}}-{{item.type}}
					</div>
					<div class="done-info" :class="getStatusClass(item)" v-if="item.isFinish">
						已完成
					</div>
					<div class="done-info" v-else-if="myTaskTab!=0&&item.isSelf" :class="getStatusClass(item)">我发起的</div>
					<div class="done-info" v-else :class="getStatusClass(item)">
						{{getStatus(item)}}
					</div>
				</div>
				
				<div class="pointer">		
					<p class="card-time">
					    发布时间 : <span>{{item.createAt}}</span>
					</p>
					<p class="card-time">
					    协作时间 : <span>{{item.taskAt}}</span>
					</p>
				</div>
				<div class="user-info flex jc_b center">
					<div class="flex ai_c">
						<img v-if="item.avatar.length>0" :src=item.avatar alt="">
						<img v-else src="~@/assets/image/global/head.png" />
						<span class="linkerName">{{item.linkerName}}</span>
					</div>
					
					<div class="done-info pointer" 
						@click="cancel(item)"
						v-if="myTaskTab!=0&&((myTaskTab==1&&item.status<3&&item.cancel==0)||(myTaskTab==2&&item.status<2))">
						撤销
					</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        data: {
            type: Array,
            value: () => []
        },
        myTaskTab: {            //是否我的任务列表 0否 1我的发布 2我的接单
            type: Number,
            value : 0
        }
    },
    data() {
        return {

        }
    },
    methods: {
        getStatusClass(item){
            const {myTaskTab:tab} = this;
            if( tab == 0 ){
                return item.isFinish ? "finish" : "";
            }else if( tab == 1 ){
				return item.cancel?"finish":item.on?item.status == 3?"gray":item.status == 2?"green":"primary":"gray"
                // return ["finish","gray",""][item.status-1]
            }else if( tab == 2 ){
                return ["blue",""][item.status-1]
            }
        },
        getStatus(item){
            const {myTaskTab:tab} = this;
            if( tab == 0 ){
                if( item.isSelf ){
                    return "我发起的"
                }
            }else if( tab == 1 ){
                if( item.cancel == 1 ){
                    return "已撤销"
                }else{
                    if( item.on ){
                        return ["待认领","选择接单人","完成"][item.status-1]
                    }else{
                        return "待审核"
                    }
                    
                }
            }else if( tab == 2 ){
                return ["已申请，待审核","完成"][item.status-1]
            }
        },
        triggerClick(item){
            this.$emit("cardClick",this.myTaskTab,item)
        },
        cancel(item){
            this.$emit("cancel",item)
        }
    }
}
</script>

<style lang="scss" scoped>
    .task-card-container {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        .task-card-item {
            width: 384px;
            height: 179px;
            border-radius: 8px;            
			box-shadow: 0px 5px 15px 0px #EFF3F7;
            margin-bottom: 9px;
            padding: 16px;
            &:nth-child(even){
                margin-left: 9px;
            }
            .task-card-content {
                // display: flex;
				.card-title{
					margin-bottom: 24px;
					position: relative;
					.left{
						font-size: 18px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #333333;
						line-height: 25px;
					}
					.done-info{
						min-width: 50px;
						height: 24px;
						line-height: 24px;
						border-radius: 4px;
						font-size: 12px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						text-align: center;
						padding: 0 8px;
						&.finish {
						    color: #666666;
							background-color: rgba(194, 194, 194, 0.19);
						}
						&.gray {
						    color: #60707D;
							background-color: rgba(195, 207, 217, 0.26);
						}
						&.primary{
							color: #F4A93D;
							background: rgba(244, 169, 61, 0.1);
						}
						&.green{
							color: #40B9A4;
							background: rgba(64, 185, 164, 0.1);
						}
						&.blue{
							color: #006AFF;
							background: rgba(0, 106, 255, 0.1);
						}
					}
				}
				.card-info{
					font-size: 12px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #F4A93D;
					line-height: 17px;
				}
				.card-time{
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #999999;
					line-height: 24px;
					+.card-time{
						margin-top: 2px;
					}
					span{
						color: #333333;
					}
				}
				.user-info{
					margin-top: 20px;
					img{
					    width: 28px;
					    height: 28px;
						border-radius: 50%;
					}
					.linkerName{
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #333333;
						line-height: 20px;
						margin-left: 8px;
					}
					.done-info{
						width: 54px;
						height: 24px;
						line-height: 24px;
						text-align: center;
						background: rgba(195,207,217,0.2);
						border-radius: 17px;
						font-weight: 600;
						color: #60707D;
						font-size: 12px;
					}
				}
            }
        }
    }
</style>
