<!--
 * Description: 我的发布-选择接单人组件
-->

<template>
    <div class="picker-list-container">
        <div v-for="(item,index) in data"
            :key="'picker_'+index"
            class="picker-item">
			<div class="item-head flex jc_b">
				<div class="item-title">
				    <img v-if="item.avatar.length>0" :src=item.avatar alt="">
				    <img v-else src="~@/assets/image/global/head.png" />
				    <div>
				        <span class="item-user">
				            {{item.username}}
				        </span><br/>
				        <span class="item-time">
				            {{item.createdAt}}
				        </span>
				    </div>
				</div>
				<span v-if="choose&&chooseIndex==index&&!finished" class="picker-selected-text">已选择</span>
				
				<el-popover
				    v-if="!choose&&!finished"
				    :offset="-230"
				    :ref="'pickerPopover_'+index"
				    placement="bottom"
				    width="520"
				    trigger="click">
				    <div class="picker-popover">
				        <div class="popover-title">
				            <img v-if="item.avatar" :src=item.avatar alt="" srcset="">
							<img v-else src="~@/assets/image/global/head.png" />
				            <div>
				                <span class="popover-user">
				                    {{item.username}}
				                </span><br/>
				                <span class="popover-time">
				                    {{item.createdAt}}
				                </span>
				            </div>
				        </div>
				        <el-input
				            type="textarea"
				            :rows="6"
				            placeholder="请输入内容"
				            v-model="remark">
				        </el-input>
				        <div class="flex jc_e ai_c pt20">
							<t-btn
								w="78px"
								h="36px"
								fs="14px"
								type="info"
								@click="hidePopover(index)" 
								class="bold">取消</t-btn>
							<t-btn
							  @click="selected(item,index)"
							  w="78px"
							  h="36px"
							  fs="14px"
							  class="bold ml10">确认</t-btn>
				        </div>
				    </div>
				    <div class="picker-button" slot="reference">选择</div>
				</el-popover>
			</div>
            
            <div class="item-info">
				<div class="info">
					电话：<span>{{item.linkerPhone}}</span>
				</div>
				<div class="info">
					附言：<span>{{item.applyRemark}}</span>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "pickerList",
    data(){
        return {
            choose : false,     //是否已经有选中的人
            chooseIndex : false,        //选中人的序号
            remark: ""          //发起人留言
        }
    },
    props: {
        data: {
            type: Array,
            value: () => []
        },
        finished : {
            type : Boolean,
            value : false
        }
    },
    mounted(){
        const {data=[]}=this;
        for( let i = 0 ; i < data.length ; i ++ ){
            let d = data[i]
            if( d.choose ){
                this.choose = true;
                this.chooseIndex = i;
                break
            }
        }
    },
    methods: {
        hidePopover(index){
            this.$refs["pickerPopover_"+index][0].doClose();
        },
        selected(item,index){
            this.hidePopover(index)
            this.choose = true;
            this.chooseIndex = index;
            this.$emit("selected",{userId:item.userId,remark:this.remark})
        },
    }
}
</script>

<style lang="scss">
    .picker-popover {
        margin: 15px;
        .popover-title {
			display: flex;
			align-items: center;
			margin-bottom: 12px;
			img {
               width: 28px;
               height: 28px;
               margin-right: 8px;
			}
            >div {
            	display: flex;
            	align-items: center;
            	font-size: 12px;
            	font-family: PingFangSC-Regular, PingFang SC;
            	font-weight: 400;
            	color: #9AAAB7;
                .popover-user {
            		margin-right: 8px;
            		color: #333333;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
		.el-textarea{
			background: #F9F9F9;
			border-radius: 8px;
			padding: 16px;
		}
        textarea {
            resize: none;
        }
    }
    .picker-button {
        width: 54px;
        height: 24px;
        background: rgba(0,106,255,0.1);
        border-radius: 17px;
		font-size: 12px;
		font-weight: 600;
		color: #006AFF;
        cursor: pointer;
		text-align: center;
		line-height: 24px;
		position: absolute;
		right: 0;
		top: 0;
    }
</style>

<style lang="scss" scoped>
    .picker-list-container{
        .picker-item {
            position: relative;
            min-height: 110px;
            padding: 16px;
            margin-bottom: 8px;
			border: 1px solid rgba(225,232,238,0.52);
			border-radius: 8px;
			.item-head{
				position: relative;				
			}
            .item-title {
                display: flex;
				align-items: center;
                img {
                    width: 28px;
                    height: 28px;
                    margin-right: 8px;
                }
                >div {
					display: flex;
					align-items: center;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #9AAAB7;
                    .item-user {
						margin-right: 8px;
						color: #333333;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            .item-info {
				margin-top: 12px;
                .info{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #60707D;
                    line-height: 18px;
					+.info{
						margin-top: 2px;
					}
                    span{
						color: #383D41;
					}
                }
            }
            .picker-selected-text {
               width: 54px;
               height: 24px;
               background: #006AFF;
               border-radius: 17px;
			   font-size: 12px;
			   font-weight: 600;
			   color: #FFFFFF;
			   text-align: center;
			   line-height: 24px;
            }
        }
    }
  
</style>
