<template>
    <el-dialog
        :visible.sync="visible"
        class="apply-dialog-container"
        width="708px">
        <template slot="title">
            <span>发起-案件协作</span>
        </template>
        <info ref="info" :edit='true' :data=form></info>
        <div class="flex jc_e ai_c">
            <!-- <t-btn
                @click="commit(true)"
                type="info"
                w="170px"
                h="36px"
                class="bold ml30">发布后，再发布一个</t-btn> -->
            <t-btn
                @click="commit"
                w="78px"
                h="36px"
                class="bold ml10">确认</t-btn>
        </div>
    </el-dialog>
</template>

<script>
import info from './dialog-info.vue'
import message from '@/mixins/message'
export default {
    name : "applyDialog",
    mixins: [message],
    components: {
        info
    },
    data() {
        return {
            visible: false,
            form: {
                provinceId:1,   //省份id
                cityId:2,      //城市id
                areaId:3,      //区域id
                address:"",     //详细地址
                linkerName:"",   //联系人
                type:1,            //类型
                typeRemark:"",   //类型补充
                taskAt:"",      //期望协作时间
                remark:""       //详情
            }
        }
    },
    mounted(){
    },
    methods: {
        show(){
            this.visible = true;
        },
        commit(oneMore=false){
            let form = this.$refs.info.getForm();
            if( form ){
                this.$api.coop.start(form).then(res=>{
                    if ( !oneMore ) this.visible = false;
                    this.success({
                        message: '发布成功',
						duration:1000
                    })
                    this.$refs.info.empty();
                    this.$emit("applyDone")
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .apply-dialog-container{
        /deep/ .el-dialog__header {
            padding-right: 50px;
            margin-bottom: 20px;
            > span:first-child {
                font-size: 18px;
                color: #111111;
                font-weight: 700;
            }
            .title-time {
                float: right;
                line-height: 26px;
                padding:0 10px;
                color: #B0B0B0;
            }
        }
    }
</style>