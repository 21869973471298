<template>
  <div class="calendar-container">
    <div class="calendar-asdie">
      <div class="asdie-title flex jc_b">
        <!-- <span class="title-tip mr12">{{ week }}</span> -->
		<div class="flex ai_c jc_c">
			<i class="item-icon"></i>
			<span>{{ formatDate(value, '.') }}</span>
			<span class="title-tip">({{week}})</span>
		</div>        
        <div @click="addDay" class="add-btn pointer not-select ml20">新建日程</div>
      </div>
      <div class="asdie-content">
        <el-scrollbar style="height: 100%;">
          <template v-if="dayList && dayList.length">
            <div
              v-for="(item, index) in dayList"
              :key="index"
              class="content-item">
              <div class="item-title">
                <p>{{ item.time }}-{{ item.endTime }}</p>
                <div class="flex">
                  <t-btn
                    @click="del(item.id)"
                    type="info"
                    w="54px"
                    h="24px"
					radius="17px"
					:plain="true"
                    fs="12px">删除</t-btn>
                  <t-btn
                    @click="editDay({ ...item, date: formatDate(value, '-') })"
                    w="54px"
                    h="24px"
					radius="17px"
					type="success"
                    class="ml8"
					:plain="true"
                    fs="12px">编辑</t-btn>
                </div>
              </div>
              <div class="item-content">
                <p class="place">{{ item.place || '暂无地点' }}</p>
                <p class="content" v-html="item.content">	
				</p>
                <div v-if="item.newUsers && item.newUsers.length" class="players mt8">
                  <span
                    v-if="item.newUsers.length === userList.length"
                    class="players-item">
                    所有人
                  </span>
                  <template v-else>
                    <span
                      v-for="v in item.newUsers"
                      :key="v.id"
                      class="players-item">
                      @{{ v.username }}
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="nothing-box pt80 mt80">
            <i class="not-icon" />
			<p class="not-tit">暂无日程</p>			
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="calendar-main">
      <!-- <p class="title">日历管理</p> -->
      <div class="flex jc_b ai_c mb24">
        <div>
          <el-select
            @change="yearChange"
            v-model="params.year"
            class="mr10"
            style="width: 192px;"
            size="medium"
            placeholder="年份">
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
          <el-select
            @change="monthChange"
            v-model="params.month"
            style="width: 192px;"
            size="medium"
            placeholder="月份">
            <el-option
              v-for="item in 12"
              :key="item"
              :label="`${item}月`"
              :value="item" />
          </el-select>
        </div>
        <div class="flex ai_c">
          <el-select
            v-model="params.userId"
            style="width: 192px;"
            size="medium"
            placeholder="请选择成员"
            clearable>
            <el-option
              v-for="item in userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId" />
          </el-select>
          <!-- <div @click="addDay" class="add-btn pointer not-select ml20"></div> -->
        </div>
      </div>
      <div class="calendar-box">
        <el-calendar v-model="value" :first-day-of-week="7">
          <template
            slot="dateCell"
            slot-scope="{date, data}">
            <div
              :class="{ has: info[formatDate(date)] && info[formatDate(date)].length }"
              class="day-box">
              <span class="holiday-tag" v-if="holidays[formatDate(date)]">{{holidays[formatDate(date)]}}</span>
              <div class="day-list" v-if="info[formatDate(date)] && info[formatDate(date)].length">
                <div
                  v-for="v in info[formatDate(date)].filter((v, i) => i < 3)"
                  :key="v.id"
                  class="day-item ellipsis">
                  {{ v.content }}
                </div>
                <span
                  v-if="info[formatDate(date)].length > 3"
                  class="not-select">···</span>
              </div>
              <div v-else-if="data.isSelected" class="add" >
                <div class="add-operation" @click="addCertainDay(date)">
                  <i class="el-icon-circle-plus-outline"></i>添加日程
                </div>
              </div>
              <span :class="{ isSelected: data.isSelected }" class="day">{{ Number(data.day.split('-')[2]) }}</span>
              <span class="week">{{ weekList[new Date(date).getDay()] }}</span>
            </div>
          </template>
        </el-calendar>
      </div>
    </div>

    <addDay
      @commit="addCommit"
      :userList="userList"
      ref="addDay"/>
    <editDay
      @commit="editCommit"
      :userList="userList"
      ref="editDay"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import message from '@/mixins/message'
import addDay from './components/add-day'
import editDay from './components/edit-day'

const initYear = () => {
  const year = new Date().getFullYear()
  let arr = []
  
  for (let i = year; i < year + 31; i++) {
    arr.push({
      label: `${i}年`,
      value: i
    })
  }
  // console.info(arr, 80)
  return arr
}
export default {
  mixins: [message],
  components: {
    addDay,
    editDay
  },
  data() {
    return {
      params: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        userId: ''
      },
      value: new Date(),
      info: {},
      yearList: initYear(),
      userList: [],
      weekList: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      dayList: [],
      holidays : {}
    }
  },
  computed: {
    week() {
      const { value } = this

      return this.weekList[value.getDay()]
    }
  },
  watch: {
    value(val) {
      const { params } = this
      const date = new Date(val)
      const year = date.getFullYear()
      const month = date.getMonth() + 1

      if (year !== params.year || month !== params.month) {
        this.params.yaer = year
        this.params.month = month
        this.getCalendarList()
      }

      this.getJournalByDay()
    },
    'params.userId'() {
      this.getCalendarList()
      this.getJournalByDay()
    },
    curGroupId(val) {
      val && this.init()
    }
  },
  methods: {
    ...mapMutations(['SETMSGDIALOG']),
    init() {
      this.getUserSelect()
      this.getCalendarList()
      this.getJournalByDay()
    },
    // 初始化时间
    formatDate(val, sp = '-') {
      const date = new Date(val)
      const year = date.getFullYear()
      const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`

      return `${year}${sp}${month}${sp}${day}`
    },
    // 获取日历数据
    getCalendarList() {
      const { params } = this

      this.$api.calendar.getCalendarList({
        ...params,
        userId: params.userId || 0
      }).then(res => {
        if (!res) return

        const info = {}
        const holidays = {};

        res.forEach(v => {
          info[v.date] = v.items || [];
          if( v.holiday ){
            holidays[v.date] = v.holiday;
          }
        })

        this.info = info;
        this.holidays = holidays;
      })
    },
    // 获取成员下拉列表
    getUserSelect() {
      const { curGroup } = this

      this.$api.calendar.getUserSelect({
        groupId: curGroup.groupId
      }).then(res => {
        this.userList = res || []
      })
    },
    // 获取侧边下拉
    getJournalByDay() {
      const { value } = this

      this.$api.calendar.getJournalByDay({
        date: this.formatDate(value),
        userId: this.params.userId || 0,
        page: 1,
        prePage: 9999
      }).then(res => {
        this.dayList = res.items || []
      })
    },
    // 年份改变
    yearChange(val) {
      const date = new Date()
      const { params: { month } } = this

      if (month === date.getMonth() + 1 && val === date.getFullYear()) {
        this.value = new Date(val, month - 1, date.getDate())
      } else {
        this.value = new Date(val, month - 1)
      }

      this.getCalendarList()
    },
    monthChange(val) {
      const date = new Date()
      const { params: { year } } = this

      if (val === date.getMonth() + 1 && year === date.getFullYear()) {
        this.value = new Date(year, val - 1, date.getDate())
      } else {
        this.value = new Date(year, val - 1)
      }

      this.getCalendarList()
    },
    // 添加
    addDay() {
      this.$refs.addDay.show()
    },
    // 添加固定日期
    addCertainDay(date){
      let time = [date.getFullYear(),String(date.getMonth()+1).length > 1 ? date.getMonth()+1:"0"+(date.getMonth()+1),String(date.getDate()).length > 1 ? date.getDate():"0"+date.getDate(),]
      time = time.join("-")
      this.$refs.addDay.show(time)
    },
    // 添加请求
    addCommit(params) {
      delete params.nowData;
      this.$api.calendar.addCalendar(params).then(() => {
        const ref = this.$refs.addDay
        ref.close()
        ref.empty()
        this.success({
          message: '添加日程成功',
		  duration:1000
        })
        this.getCalendarList()
        this.getJournalByDay()
      })
    },
    // 删除日程
    del(id) {
      this.SETMSGDIALOG({
        title: '删除日程',
        message: '是否删除该日程？',
		width:"439px",
		type:"error",
        commit: () => {
          this.$api.calendar.delCalendar({ id }).then(() => {
            this.success({
              message: '删除成功'
            })
            this.getCalendarList()
            this.getJournalByDay()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
    // 编辑
    editDay(row) {
      this.$refs.editDay.show(row)
    },
    // 编辑请求
    editCommit(params) {
      this.$api.calendar.editCalendar(params).then(() => {
        const ref = this.$refs.editDay
        ref.close()
        this.success({
          message: '编辑日程成功',
		  duration:1000
        })
        this.getCalendarList()
        this.getJournalByDay()
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
  .calendar-container {
    display: flex;
    height: 100%;
    background: $secondary_bg;
    .calendar-asdie {
      display: flex;
      flex-direction: column;
      width: 324px;
      background: #fff;
	  padding: 0 16px;
	  height: 100%;
      .asdie-title {
        width: 100%;
        border-bottom: 1px solid $line;
        font-size: 20px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        line-height: 28px;
        color: $primary_text;
		padding: 18px 0;
		.item-icon{
			width: 20px;
			height:20px;
			margin-right: 8px;
            background-image: url('../../assets/image/global/icon_calendar.png');
			background-size: 100% 100%;
		}
        .title-tip {
          font-size: 14px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #9AAAB7;
          line-height: 20px;
		  margin-left: 8px;
        }
        .add-btn {
          width: 78px;
          height: 32px;
          border-radius: 17px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 32px;		  
		  background: #006AFF;
		  text-align: center;
        }
      }
      .asdie-content {
        flex: 1;
		height: calc(100% - 68px);
		overflow-y: scroll;
		&::-webkit-scrollbar{width:0;}
        .content-item {
		  padding: 4px 0;
          .item-title {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #383D41;
            line-height: 22px;
			margin: 18px 0;
			padding-left: 14px;
            &::after {
              content: '';
              position: absolute;
              width: 8px;
              height: 8px;
              background: $danger;
              border-radius: 50%;
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
          .item-content {
            padding: 14px;
            padding-bottom: 4px;            
			background: rgba(248,249,251,0.8);
            border-radius: 8px;
            .place {
              position: relative;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 22px;
              color: $primary_text;
              padding-left: 18px;
              &::after {
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                background: url('../../assets/image/global/icon_place.png') no-repeat;
                background-size: 100%;
                left: 0;
                top: 2px;
              }
            }
            .content {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #60707D;
              line-height: 22px;
              color: $tertiary_text;
			  margin-top: 10px;
            }
            .players {
              display: flex;
              flex-wrap: wrap;
              &-item {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #006AFF;
                line-height: 22px;
                color: #056BFD;
				margin-bottom: 4px;
				margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .calendar-main {
      width: 878px;
	  background: #FFFFFF;
	  border-radius: 16px;
      padding: 16px;
	  margin-left: 16px;
	  overflow-y: scroll;
	  &::-webkit-scrollbar{width:0;}
      .title {
        margin-bottom: 25px;
        font-size: 20px;
        font-weight: 600;
        color: $primary_title;
      }
      .add-btn {
        position: relative;
        width: 36px;
        height: 36px;
        background: $primary;
        border-radius: 50%;
        box-shadow: 0px 4px 12px 0px rgba(5, 107, 253, 0.3);
        &::after {
          content: '';
          position: absolute;
          width: 15px;
          height: 16px;
          background: url('../../assets/image/global/date_icon_add.png') no-repeat;
          background-size: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .calendar-box {
        overflow: hidden;
		border-radius: 8px;
		overflow: hidden;
        .day-box {
          position: relative;
          width: 100%;
          height: 100%;
          background: #fff;
		  border-radius: 8px;
		  padding: 34px 10px 10px;
          &:hover {
            background: #F9FAFC;
          }
          &.has {
            background: #F9FAFC;
          }
		  .add-operation{
			  font-size: 12px;
			  color: #40B9A4;
			  width: 98px;
			  height: 36px;
			  line-height: 36px;
			  background: rgba(64,185,164,0.06);
			  border-radius: 8px;
			  border: 1px solid rgba(64,185,164,0.15);
			  i{
				  margin-right:5px;
			  }
		  }
          .holiday-tag {
            position: absolute;
            display: block;
            top: 8px;
            left: 34px;
			line-height: 17px;
			padding: 0 5px;
            background: rgba($color: #056BFD, $alpha: 0.08) ;
            border-radius: 6px;
            font-size: 12px;
            font-weight: 600;
            color: #056BFD;
			transform: scale(0.8);
          }

          .add {
            padding-top: 20px;
            text-align: center;
            color : #CCCCCC
          }
          .day {
            position: absolute;
            width: 26px;
            height: 26px;
            line-height: 26px;
            border-radius: 50%;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: #FFFFFF;
            top: 4px;
            right: 4px;
            &.isSelected {
				color: #FFFFFF;
				background: #006AFF;
            }
          }
          .week {
            position: absolute;
            font-size: 12px;
            color: #60707D;
            left: 9px;
            top: 8px;
          }
		  .day-list{
			  width: 100%;
			  height: 68px;
			  .not-select,.day-item{
				 font-size: 12px;
				 font-family: PingFangSC-Regular, PingFang SC;
				 font-weight: 400;
				 color: #60707D;
				 line-height: 17px;
				 padding-left: 7px; 
			  }
		  }
          .day-item {
            position: relative;            
            &::after {
              content: '';
              position: absolute;
              width: 4px;
              height: 4px;
              background: #C3CFD9;
              border-radius: 50%;
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
</style>