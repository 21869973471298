<template>
    <el-dialog
        :visible.sync="visible"
        @close=close  @opened="opened"
        class="task-dialog"
        width="708px">
        <template slot="title">
            <span>{{data.area}}-{{data.typeName || data.type}}</span>            
        </template>
        <div class="task-content">
			<div class="task-date">
				<span class="btn yellow" v-if="stepShow == 0">待认领</span>
				<span class="btn green" v-else-if="stepShow == 1">选择接单人</span>
				<span class="btn" v-else-if="stepShow == 2">已完成</span>
				<span class="title-time">
					期待完成时间：<span class="time">{{data.taskAt}}</span>
				</span>
				<span class=""> |</span>
				<span class="title-time">
					发布时间：<span class="time">{{data.createAt}}</span>
				</span>
			</div>
            <div class="task-timeline">
				<div class="timeline" :class="{on:stepShow > 0}" @click="stepSelect(0)">
					<img  src="@/assets/image/cooperation/icon_step_0.png"/>
					<span>发起</span>
				</div>
				<div class="line" :class="{on:stepShow > 0}"></div>
				<div class="timeline blue" :class="{on:stepShow > 1}" @click="stepSelect(1)">
					<img  src="@/assets/image/cooperation/icon_step_0.png" v-if="stepShow > 1"/>
					<img  src="@/assets/image/cooperation/icon_step_1.png" v-else/>
					<span>接单</span>
				</div>
				<div class="line" :class="{on:stepShow > 1}"></div>
				<div class="timeline" :class="{on:stepShow == 2}" @click="stepSelect(2)">
					<img  src="@/assets/image/cooperation/icon_step_active_2.png" v-if="stepShow == 2"/>
					<img  src="@/assets/image/cooperation/icon_step_2.png" v-else/>
					<span>完成</span>
				</div>
            </div>
            <info :data=data v-if="nowTab==0" :edit=infoEdit></info>
            <picker-list :data=data.detail @selected=selectedOne :finished=data.isFinish v-if="nowTab==1&&type==1"></picker-list>
            <div class="apply-task-info" v-if="nowTab==1&&type==2">
				<div class="task-user">
					<img v-if="data.detail.avatar.length>0" :src=data.detail.avatar alt="">
					<img v-else src="~@/assets/image/global/head.png" />
					<div>
					    <span class="item-user">
					        {{data.detail.username}}
					    </span><br/>
					    <span class="item-time">
					        {{data.detail.createdAt}}
					    </span>
					</div>
				</div>
				<div class="task-info">
					<div class="info-tit">我的申请：</div>
					<div class="info">
						电话：<span>{{data.detail.linkerPhone}}</span>
					</div>
					<div class="info">
						附言：<span>{{data.detail.applyRemark}}</span>
					</div>
				</div>                
            </div>
            <div v-if="nowTab==2" class="task-picker-selected">
				<div class="picker flex jc_b ai_c">
					<div v-if="type==1" class="picker-user">
					    <img v-if="choose.avatar.length>0" :src=choose.avatar alt="">
					    <img v-else src="~@/assets/image/global/head.png" />
					    <div>
					        <span class="item-user">
					            {{choose.username}}
					        </span><br/>
					        <span class="item-time">
					            {{choose.createdAt}}
					        </span>
					    </div>
					</div>				
					<div class="confirm-text">{{confirmText}}</div>
				</div>
                
                <div class="picker-info">
                    <div class="info" v-if="type==1">
						<div class="info">
							电话：<span>{{choose.linkerPhone}}</span>
						</div>
						<div class="info">
							附言：<span>{{choose.applyRemark}}</span>
						</div>
                    </div>
                    <div v-if="type==1" :class="{border:type==1}">
						<div class="info">
							我的附言：<span>{{choose.remark}}</span>
						</div>
                    </div>
                    <div v-if="type==2">
                        <div class="info">
                        	电话：<span>{{data.detail.linkerPhone}}</span>
                        </div>
                        <div class="info">
                        	附言：<span>{{data.detail.applyRemark}}</span>
                        </div>
                    </div>
                    <!-- <div v-if="type==2">
						<div class="info">
							我的附言：<span>{{data.detail.remark}}</span>
						</div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="flex jc_b ai_c">
			<div class="flex ai_c">
				<el-popover
				    v-show="type==2"
				    v-model="complaintPopover"
				    :offset="-230"
				    placement="bottom"
				    width="520"
				    trigger="click">
				    <div class="complaint-popover">
				        <h4>投诉</h4>
				        <div>
				            <el-radio-group v-model="complaint.type">
				                <el-radio :label="1">违法信息</el-radio>
				                <el-radio :label="2">广告</el-radio>
				                <el-radio :label="3">人身攻击</el-radio>
				                <el-radio :label="4">不实信息</el-radio>
				                <el-radio :label="5">其他</el-radio>
				            </el-radio-group>
							<div class="recive-editor">
							    <div class="editor-toolbar" id="recive-toolbar-container"></div>
							</div>
				        </div>
				        <div class="flex jc_e ai_c pt20">
				            <t-btn 
								w="78px"
								h="36px"
								fs="14px"
								type="info"
								@click="complaintPopover = false" 
								class="bold" >取消</t-btn>
				            <t-btn
				                @click="complain"
				                w="78px"
				                h="36px"
								fs="14px"
								
				                class="bold ml30">提交</t-btn>
				        </div>
				    </div>
					<div class="flex ai_c complaint-button" slot="reference">
						<img src="@/assets/image/login/login_img_tips.png"/>
						<span>投诉</span>
					</div>
				</el-popover>
			</div>
			<div class="flex jc_e ai_c"  v-if="type==1&&!data.isFinish">
				<t-btn
					w="78px"
					h="36px"
					fs="14px"
					type="info"
					@click="visible = false" 
					class="bold">取消</t-btn>
				<t-btn v-show="nowTab==0&&infoEdit==false" 
				    @click="infoEdit=true"
				    w="78px"
				    h="36px"
					fs="14px"
				    class="bold ml30">编辑</t-btn>
				<t-btn v-show="nowTab==0&&infoEdit==true" 
				    @click="commit"
				    w="78px"
				    h="36px"
					fs="14px"
				    class="bold ml30">确认</t-btn>
				<t-btn v-show="nowTab==1" 
				    @click="confirmSelect"
				    w="78px"
				    h="36px"
					fs="14px"
				    class="bold ml30">确认</t-btn>
			</div>
        </div>
    </el-dialog>
</template>

<script>
import message from '@/mixins/message'
import info from './dialog-info.vue'
import PickerList from './picker-list.vue'
import E from 'wangeditor'

export default {
    name : "myTask",
    mixins: [message],
    props :{
        type : {                //弹窗类型  1我的发布  2我的接单
            type : Number,
            default : 1
        },
    },
    components: {
        info,
        PickerList
    },
	filters:{
		filterStep(step){
			const statusMap = {
			  0: '选择接单人',
			  1: '待认领',
			  2:"已完成"
			};
			return statusMap[step];
		}
	},
    data() {
        return {
            visible: false,
            infoEdit : false,           //表单是否可以编辑
            complaintPopover: false,
            complaint: {
                type:1
            },
            nowTab : 0,
            stepShow : 0,               //用来显示过往页
            selectedParam : null,       //选中人的信息
            // steps: ["发起","选择接单人","完成"],
			
			steps: [
				{img:"",imgActive:"",name:"发起"},
				{img:"",imgActive:"",name:"选择接单人"},
				{img:"",imgActive:"",name:"完成"},
			],
            choose : {},          //选中的接单人
            data: {}
        }
    },
    computed: {
        confirmText(){
            if( this.type == 1 ){
				console.log('choose-----')
                return "已确认"
				
            }else{
                const {detail:{choose}} = this.data;
				console.log('choose')
				console.log(choose)
                if( choose ){
                    return "已确认完成"
                }else{
                    return "接单失败"
                }
            }
        }
    },
    watch: {
        type: {
            handler(val){
                let secStr = "";
                if( val == 1 ){
                    secStr = "选择接单人"
                }else if( val == 2 ){
                    secStr = "申请任务"
                }
                this.steps.splice(1,1,secStr)
            },
            immediate : true
        }
    },
    mounted(){
        
    },
    methods: {

		commit(oneMore=false){
           // let form = this.$refs.info.getForm();
            if( this.data ){
                this.$api.coop.edit(this.data ).then(res=>{
                  
                    this.success({
                        message: '编辑成功',
						duration:1000
                    })
                }).catch(err =>{
					this.infoEdit=false
				})
            }
        },


		opened(){
			if(this.visible){
				const editor = new E('#recive-toolbar-container') // 工具栏和编辑器分开
				editor.config.zIndex = 100;             //设定层级  
				editor.config.menuTooltipPosition = 'down'      //工具栏提示在下方
				editor.config.placeholder = '请输入投诉信息'
				editor.config.menus = [];
				editor.create()
				this.editor = editor;
			}
		},
        stepSelect(index){
            const {type,data} = this;
            if( index == 2 ){
                if( !data.isFinish ){
                    return false;
                }
            }else if( index == 1 ){
                if( type == 1 ){
                    if( data.detail.length == 0 ){
                        return false;
                    }
                }
            }
            this.nowTab = index;
        },
        // 选中某个人
        selectedOne(sparam){
            this.selectedParam = sparam;
        },
        // 确认选中某个人
        confirmSelect(param){
            const {selectedParam,data:{id}} = this;
            let params = {
                ...selectedParam,
                id
            }
            this.$api.coop.choose(params).then(res => {
                this.success({
                    message: '选择成功!',
					duration:1000
                })
                this.visible = false;
                this.$emit("afterConfirm")
            })
        },
        // 显示
        show(data,self) {
            let detail = data.detail || [];
            for( let i = 0 ; i < detail.length ; i ++ ){
                const d = detail[i];
                if( d.choose == 1 ){
                    this.choose = d;
                    break;
                }
            }
            this.visible = true;
            this.$set(this,"data",data);
            if( self ){
                this.nowTab = data.status - 1;
            }else{
                if( data.isFinish ){
                    this.nowTab = 2;
                }else{
                    this.nowTab = 1;
                }
            }
            this.stepShow = this.nowTab;
        },
        close() {
            this.visible = false;
            this.infoEdit = false;
            this.nowTab = 0;
            this.choose = {};      
            this.data = {};    
        },
        complain(){
            const {complaint:{type}} = this;
            const remark = this.editor.txt.text();
            const id = this.data.id
            
            this.$api.coop.complaint({
                type,remark,id,source:2
            }).then(() => {
                this.success({
                    message: '投诉成功!',
					duration:1000
                })
                this.complaintPopover = false;
                this.visible = false;
                this.complaint = {
                    type: 1,
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
	.el-textarea .el-textarea__inner{
		border-color: #DCDFE6;
	}
    .task-dialog{
		.task-content{
			min-height: 390px;
		}
        .complaint-button {
            font-size: 14px;
            font-weight: 400;
            color: #60707D;
            line-height: 20px;
			position: relative;
			cursor: pointer;
			img{
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
        }
		.task-date{
			margin-bottom: 25px;
			.btn{
				height: 24px;
				line-height: 24px;
				border-radius: 4px;
				padding: 0 7px;
				font-size: 12px;
				display: inline-block;
				color: #666666;
				background: rgba(194, 194, 194, 0.19);
				&.yellow{
					color: #F4A93D;
					background: rgba(244, 169, 61, 0.1);					
				}
				&.green{
					color: #40B9A4;
					background: rgba(64, 185, 164, 0.1);
				}
			}
			.title-time {
			    font-size: 14px;
			    font-family: PingFangSC-Regular, PingFang SC;
			    font-weight: 400;
			    color: #999999;
			    line-height: 24px;
				padding: 0 10px;
				.time{
					color: #333333;
				}
			}
		}
        .task-timeline {
			margin-bottom: 22px;
            display: flex;
            align-items: center;
			justify-content: center;
			.timeline{
				display: flex;
				align-items: center;
				cursor: pointer;
				font-weight: 500;
				color: #60707D;
				font-size: 14px;
				span{
					padding:0 3px;
				}
				&.blue{
					color: #056BFD;
				}
				&.on{
					color: #40B9A4;
				}
			}
			.line{
				margin: 0 40px;
				width: 60px;
				height: 3px;
				background: #F0F0F0;
				border-radius: 2px;
				&.on{
					background: #40B9A4;
				}
			}
            > div {
                img{
					width: 48px;
					height: 48px;
				}
            }
        }
        .apply-task-info {
			min-height: 110px;
			padding: 16px;
			margin-bottom: 28px;
			border: 1px solid rgba(225,232,238,0.52);
			border-radius: 8px;
			font-weight: 500;
			color: #60707D;
			font-size: 14px;
			.task-user{
				display: flex;
				align-items: center;
				img {
				    width: 28px;
				    height: 28px;
				    margin-right: 8px;
				}
				>div {
				   display: flex;
				   align-items: center;
				   font-size: 12px;
				   font-family: PingFangSC-Regular, PingFang SC;
				   font-weight: 400;
				   color: #9AAAB7;
				   .item-user {
				   	margin-right: 8px;
				   	color: #333333;
				       font-size: 14px;
				       font-weight: bold;
				   }
				}
			}
			.task-info{
				margin-top: 12px;
				.info{
				    font-size: 12px;
				    font-family: PingFangSC-Regular, PingFang SC;
				    font-weight: 400;
				    color: #60707D;
				    line-height: 18px;
					+.info{
						margin-top: 2px;
					}
				    span{
						color: #383D41;
					}
				}
				.info-tit{
					font-weight: 500;
					color: #333333;
					line-height: 20px;
					font-size: 14px;
					margin-top: 12px;
				}
			}			
        }
        .task-picker-selected {
           position: relative;
           min-height: 110px;
           padding: 16px;
           margin-bottom: 28px;
           border: 1px solid rgba(225,232,238,0.52);
           border-radius: 8px;
            .picker-user {
                display: flex;
                align-items: center;
                img {
                    width: 28px;
                    height: 28px;
                    margin-right: 8px;
                }
                >div {
                   display: flex;
                   align-items: center;
                   font-size: 12px;
                   font-family: PingFangSC-Regular, PingFang SC;
                   font-weight: 400;
                   color: #9AAAB7;
                   .item-user {
                   	margin-right: 8px;
                   	color: #333333;
                       font-size: 14px;
                       font-weight: bold;
                   }
                }
            }
            .picker-info {
				margin-top: 12px;
                .info{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #60707D;
                    line-height: 18px;
                	+.info{
                		margin-top: 2px;
                	}
                    span{
                		color: #383D41;
                	}
                }
				.info-tit{
					font-weight: 500;
					color: #333333;
					line-height: 20px;
					font-size: 14px;
					margin-top: 12px;
				}
            }
            .confirm-text {
                font-size: 12px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #006AFF;				
            }
        }
        .form-item-box {
            position: relative;
            padding: 35px 20px 0;
            padding-right: 30px;
            background: #F8FAFB;
            border-radius: 4px;
            overflow: hidden;
            .el-form-item {
                .el-date-editor {
                    width: 100%;
                }   
            }
        }
        h4 {
            margin: 20px 0 10px;
            color: #aaa;
        }
    }
	
	.complaint-popover {
	    padding: 20px;
	    h4 {
	        margin-bottom: 20px;
	    }
		.recive-editor{
			margin: 28px 0 24px;
			.editor-toolbar {
			    .w-e-toolbar {
			        height: 0;
			        overflow: hidden;
			    }
			    .w-e-text-container {
			        height: 175px!important;
			    }
			}
		}
	}
</style>
