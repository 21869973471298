<template>
  <el-dialog
    @closed="empty"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="708px"
    title="编辑日程">
    <el-form
      class="t-form"
      label-width="80px"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="选择日期">
        <el-date-picker
          v-model="params.date"
          :clearable="false"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <div>
        <div
          class="form-item-box">
		  <div class="flex jc_b item-box-title">
			  <span>日程{{numberToChanie(i+1)}}</span>
		  </div>
          <div class="double-cell">
            <el-form-item label="选择时间" class="mr52">
              <div class="flex ai_c">
                <el-time-picker
                  v-model="params.time"
                  :picker-options="{
                    selectableRange: `00:00:00 - ${params.endTime ? params.endTime + ':00' : '23:59:59'}`
                  }"
                  style="width: 100%;"
                  placeholder="开始时间"
                  value-format="HH:mm"
                  format="HH:mm"/>
                <span class="ml10 mr10">-</span>
                <el-time-picker
                  v-model="params.endTime"
                  :picker-options="{
                    selectableRange: `${params.time ? params.time + ':59' : '00:00:00'} - 23:59:59`
                  }"
                  style="width: 100%;"
                  placeholder="结束时间"
                  value-format="HH:mm"
                  format="HH:mm"/>
              </div>
            </el-form-item>
            <el-form-item label="经办人">
              <el-select
                v-model="params.userIds"
                placeholder="请选择经办人"
                multiple>
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId" />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="计划内容">
            <el-input v-model="params.content" type="textarea" rows="6" placeholder="请输入计划内容" resize="none" />
          </el-form-item>
          <el-form-item label="地点">
            <el-input v-model="params.place" placeholder="请输入地点" />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="flex jc_b mt24">
		<div></div>
		<div class="flex jc_e ai_c">
		  <t-btn 
			w="78px"
			h="36px"
			fs="14px"
			type="info"
			@click="visible = false" 
			class="bold">取消</t-btn>
		  <t-btn
		    @click="commit"
		    w="78px"
		    h="36px"
		    class="bold ml30">确认</t-btn>
		</div>
	</div>
    
  </el-dialog>
</template>

<script>
import message from '@/mixins/message'

const initParams = () => ({
  id: '',
  date: '',
  time: '',
  endTime: '',
  place: '',
  content: '',
  userIds: []
})
export default {
  mixins: [message],
  props: {
    userList: {
      type: Array,
      value: () => []
    }
  },
  data() {
    return {
      visible: false,
      params: initParams()
    }
  },
  methods: {
	numberToChanie(num){
		let chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
		let chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
		let chnUnitChar = ["", "十", "百", "千"];
		let strIns = '', chnStr = '';
		let unitPos = 0;
		let zero = true;
		 while (num > 0) {
		    let v = num % 10;
		    if (v === 0) {
		      if (!zero) {
		        zero = true;
		        chnStr = chnNumChar[v] + chnStr;
		      }
		    } else {
		      zero = false;
		      strIns = chnNumChar[v];
		      strIns += chnUnitChar[unitPos];
		      chnStr = strIns + chnStr;
		    }
		    unitPos++;
		    num = Math.floor(num / 10);
		}
		return chnStr;
	},
    // 确认
    commit() {
      const { params } = this
      let msg = ''

      if (!params.date) {
        msg = '请选择日程日期'
      } else if (!params.time) {
        msg = '请选择日程的开始时间'
      } else if (!params.endTime) {
        msg = '请选择日程的结束时间'
      } else if (!params.userIds.length) {
        msg = '请选择日程的参与人'
      } else if (!params.content) {
        msg = '请填写日程的内容'
      } else {
        msg = ''
      }

      if (msg) {
        this.error({
          message: msg
        })
        return
      }

      this.$emit('commit', params)
    },
    // 显示
    show(row) {
      Object.keys(this.params).forEach(v => {
        if (v === 'userIds') {
          this.params[v] = row.newUsers.map(v => v.userId)
        } else {
          this.params[v] = row[v]
        }
      })
      this.visible = true
    },
    close() {
      this.visible = false
    },
    empty() {
      this.params = initParams()
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-item-box {
  	position: relative;
  	padding: 16px 16px 4px;
  	background: #F7FAFB;
  	border-radius: 8px;
  	overflow: hidden;
  	.item-box-title{
  		margin-bottom: 10px;
  		span{
  			font-size: 14px;
  			font-family: PingFangSC-Medium, PingFang SC;
  			font-weight: 500;
  			color: #9AAAB7;
  			line-height: 18px;
  		}
  		.del-icon{
  			color: #60707D;
  		}
  	}
  	.el-form .el-form-item {
  		margin-bottom: 12px;
  	}
  	&:not(:last-child) {
  	  margin-bottom: 16px;
  	}
  }
</style>
