<template>
    <el-dialog
        :visible.sync="visible"
        @close=close @opened="opened"
        class="custom-dialog"
        width="708px">
        <template slot="title">
            <span>{{data.area}}-{{data.typeName}}</span>
            <!-- :visible-arrow="false" -->   
        </template>
		<div class="apply-date flex ai_c">
			<span class="title-time">
				期望完成时间：<span class="time">{{data.taskAt}}</span>
			</span>
			<span class=""> |</span>
			<span class="title-time">
				发布时间：<span class="time">{{data.createAt}}</span>
			</span>
		</div>
        <info :data=data></info>
		<div class="apply-info-container">
			<h4>申请接单</h4>
			<el-form
				class="t-form"
				label-width="68px"
				size="medium"
				hide-required-asterisk>
			<el-form-item label="我的电话">
				<el-input v-model="form.linkerPhone" type="text" rows="4" placeholder="请输入联系电话" resize="none" />
			</el-form-item>
			<el-form-item label="详情">
				<el-input v-model="form.applyRemark" type="textarea" rows="4" placeholder="附言可为空" resize="none" />
			</el-form-item>
			</el-form>
		</div>
        
        <div class="flex jc_b ai_c">
			<div class="flex ai_c">				
				<el-popover
				    v-model="complaintPopover"
				    :offset="-230"
				    placement="bottom"
				    width="520"
				    trigger="click">
				    <div class="complaint-popover">
				        <h4>投诉</h4>
				        <div>
				            <el-radio-group v-model="complaint.type">
				                <el-radio :label="1">违法信息</el-radio>
				                <el-radio :label="2">广告</el-radio>
				                <el-radio :label="3">人身攻击</el-radio>
				                <el-radio :label="4">不实信息</el-radio>
				                <el-radio :label="5">其他</el-radio>
				            </el-radio-group>
				            <div class="complaint-editor">
				                <div class="editor-toolbar" id="complaint-toolbar-container"></div>
				            </div>
				        </div>
				        <div class="flex jc_e ai_c">
							<t-btn
								w="78px"
								h="36px"
								fs="14px"
								type="info"
								@click="complaintPopover = false" 
								class="bold">取消</t-btn>
				            <t-btn
				                @click="complain"
				               w="78px"
				               h="36px"
				               fs="14px"
				                class="bold ml30">提交</t-btn>
				        </div>
				    </div>
					<div class="flex ai_c complaint-button" slot="reference">
						<img src="@/assets/image/login/login_img_tips.png"/>
						<span>投诉</span>
					</div>
				</el-popover>
			</div>
			<div class="flex ai_c jc_e">
				<t-btn
					w="78px"
					h="36px"
					fs="14px"
					type="info"
					@click="visible = false" 
					class="bold">取消</t-btn>
				<t-btn
				  @click="commit"
				  w="78px"
				  h="36px"
				  fs="14px"
				  class="bold ml10">确认</t-btn>
			</div>           
        </div>
    </el-dialog>
</template>

<script>
import message from '@/mixins/message'
import info from './dialog-info.vue'
import E from 'wangeditor'

export default {
    name : "detail",
    mixins: [message],
    components : {
        info
    },
    props: {
        userList: {
            type: Array,
            value: () => []
        }
    },
    data() {
        return {
            visible: false,
            complaintPopover: false,
            complaint: {
                type:1
            },
            data: {
                id: "",
                linkerName: "",
                avatar: "",
                area: "",
                type: "",
                isSelf: false,
                isFinish: false,
                taskAt: "",
                createAt: ""
            },
            form : {
                linkerPhone: "",
                applyRemark : ""
            }
        }
    },
    mounted(){
		
    },
    methods: {
        // 显示
        show(data) {
            this.visible = true
            this.$set(this,"data",data)
        },
		opened(){
			if(this.visible){
				const editor = new E('#complaint-toolbar-container') // 工具栏和编辑器分开
				editor.config.zIndex = 100;             //设定层级  
				editor.config.menuTooltipPosition = 'down'      //工具栏提示在下方
				editor.config.placeholder = '请输入投诉信息'
				editor.config.menus = [];
				editor.create()
				this.editor = editor;
			}			
		},
        close() {
            this.visible = false
            this.form = {
                linkerPhone: "",
                applyRemark : ""
            }
        },
        commit(){
            this.$api.coop.apply({
                ...this.form,
                id : this.data.id
            }).then(() => {
                this.success({
                    message: '申请任务成功!',
					duration:1000
                })
                this.visible = false
                this.form = {
                    linkerPhone: "",
                    applyRemark : ""
                }
                this.$emit("afterConfirm")
            })
        },
        complain(){
            const {complaint:{type}} = this;
            const remark = this.editor.txt.text();
            const id = this.data.id
            
            this.$api.coop.complaint({
                type,remark,id,source:2
            }).then(() => {
                this.success({
                    message: '投诉成功!',
					duration:1000
                })
                this.complaintPopover = false;
                this.visible = false;
                this.complaint = {
                    type: 1,
                }
            })
        }
    }
}
</script>

<style lang="scss" >
	
	.apply-date{
		margin-bottom: 24px;
		.btn{
			height: 24px;
			line-height: 24px;
			border-radius: 4px;
			padding: 0 7px;
			font-size: 12px;
			display: inline-block;
			color: #666666;
			background: rgba(194, 194, 194, 0.19);
			&.yellow{
				color: #F4A93D;
				background: rgba(244, 169, 61, 0.1);					
			}
			&.green{
				color: #40B9A4;
				background: rgba(64, 185, 164, 0.1);
			}
		}
		.title-time {
		    font-size: 14px;
		    font-family: PingFangSC-Regular, PingFang SC;
		    font-weight: 400;
		    color: #999999;
		    line-height: 24px;
			padding: 0 10px;
			.time{
				color: #333333;
			}
		}
	}
	.apply-info-container{
		background: #F9F9F9;
		padding: 12px 16px 16px;
		border-radius: 8px;
		margin-top: 12px;
		.el-form.t-form .el-form-item__label{}
	}
    .complaint-popover {
        padding: 20px;
        h4 {
            margin-bottom: 20px;
        }
		.complaint-editor{
			margin: 28px 0 24px;
			.editor-toolbar {
			    height: 175px;
				>div{
					border-radius: 4px;
					border: 1px solid #D0D0D0!important;
					border-top: none!important;
				}
			    .w-e-toolbar {
			        height: 0;
			        overflow: hidden;
			    }
			    .w-e-text-container {
			        height: 100% !important;
			    }
			}
		}
    }
</style>

<style lang="scss" scoped>
    .custom-dialog{
        .el-dialog__header {
            > span:first-child {
                font-size: 18px;
                color: #111111;
                font-weight: 700;
            }
        }
		
		.complaint-button {
		    font-size: 14px;
		    font-weight: 400;
		    color: #60707D;
		    line-height: 20px;
			position: relative;
			cursor: pointer;
			img{
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
		}
        .form-item-box {
            position: relative;
            padding: 35px 20px 0;
            padding-right: 30px;
            background: #F8FAFB;
            border-radius: 4px;
            overflow: hidden;
            .el-form-item {
                .el-date-editor {
                    width: 100%;
                }   
            }
        }
        h4 {
           font-size: 14px;
           font-weight: 500;
           color: #60707D;
           line-height: 18px;
        }
    }
  
</style>
