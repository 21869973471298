<template>
    <div :class="{'dialog-info-container':(!edit)}">
        <el-form
                class="t-form"
                label-width="70px"
                size="medium"
                :rules="rules"
                :model="form"
                ref="form">
            <div class="form-item-box">
				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="协作地域" prop="regionValue">
							<el-cascader
							    v-model="form.regionValue"
							    separator="-"
							    :disabled=!edit
							    :options="regions"
							    :props="{value:'id',label:'name'}"
							    @change="handleChange">
							</el-cascader>
							<!-- <el-col :span="11">
							    <el-select @change="changeProvince" v-model="form.provinceId" :disabled=!edit placeholder="请选择协作省份">
							        <el-option v-for="pro in regions" :label=pro.name :value=pro.id></el-option>
							    </el-select>
							</el-col>
							<el-col :span="12" :offset="1">
							    <el-select v-model="form.cityId" :disabled=!edit placeholder="请选择协作城市">
							        <el-option v-for="city in citys" :label=city.name :value=city.id></el-option>
							    </el-select>
							</el-col> -->
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="协作类型">
						    <el-select v-model="form.type" :disabled=!edit placeholder="请选择协作类型">
						        <el-option v-for="(item,index) in types"
						            :key="'type_'+index"
						            :label="item"
						            :value="index+1">
						        </el-option>
						    </el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="详细地址" prop="address">
						    <el-input v-model="form.address" :disabled=!edit type="text" rows="2" placeholder="请输入详细地址" resize="none" />
						</el-form-item>
					</el-col>				
				</el-row>
				<el-row :gutter="24" v-if="self">
					<el-col :span="24" v-show="form.type==5">
						<el-form-item label="类型说明">
						    <el-input v-model="form.typeRemark" :disabled=!edit type="textarea" rows="2" 
							placeholder="请输入其他类型说明" resize="none" />
						</el-form-item>
					</el-col>	
					<el-col :span="12">
						<el-form-item label="联系人" prop="linkerName">
						    <el-input v-model="form.linkerName" :disabled=!edit type="text" rows="4" placeholder="请输入联系人" resize="none" />
						</el-form-item>
					</el-col>	
					<el-col :span="12">
						<el-form-item prop="taskAt" label="协作时间">
						    <el-date-picker
						        type="datetime"
						        :disabled=!edit
						        v-model="form.taskAt"
						        :clearable="false"
						        value-format="yyyy-MM-dd HH:mm:ss"
						        format="yyyy-MM-dd HH:mm:ss"
						        placeholder="选择日期">
						    </el-date-picker>
						</el-form-item>
					</el-col>					
				</el-row>
				<el-row :gutter="24" v-else>
					<el-col :span="12">
						<el-form-item label="联系人" prop="linkerName">
						    <el-input v-model="form.linkerName" :disabled=!edit type="text" rows="4" placeholder="请输入联系人" resize="none" />
						</el-form-item>
					</el-col>	
					<el-col :span="12">						
						<el-form-item label="日期" prop="taskAt">
							<el-date-picker
							    type="datetime"
							    :disabled=!edit
							    v-model="form.taskAt"
							    :clearable="false"
							    value-format="yyyy-MM-dd hh:mm:ss"
							    format="yyyy-MM-dd hh:mm:ss"
							    placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row  :gutter="24">
					<el-col :span="24">
						<el-form-item label="详情" prop="remark">
						    <el-input v-model="form.remark" 
							:disabled=!edit type="textarea" rows="4" max="1000" placeholder="请输入详情，不超过1000字" resize="none" />
						</el-form-item>
					</el-col>
				</el-row>                
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    name : "dialogInfo",
    inject : ["REGIONS"],
    props: {
        edit : {
            type : Boolean,
            default : false
        },
        self : {            //是否本人发起
            type : Boolean,
            default : true
        },
        data:{
            type : Object,
            default : ()=>{}
        }
    },
    data(){
        return {
            types:["辩护/代理","会见","调查取证","阅卷","异地查档","其他"],
            rules: {
                regionValue: [
                    { required: true, message: '请选择协作地域', trigger: 'change' }
                ],
                address : [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
                linkerName : [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                taskAt : [
                    { required: true, message: '请选择协作时间', trigger: 'change' }
                ],
            },
            form:{
                regionValue : "",
                provinceId : "",
                cityId : "",
                areaId: "",
                address: "",
                linkerName: "",
                type: 1,
                typeRemark:"",
                remark: "",
                taskAt: ""
            },
            citys : []
        }
    },
    computed:{
        regions(){
            return this.REGIONS.data;
        }
    },
    watch: {
        data(){
            this.setForm();
        }
    },
    mounted(){
        this.setForm();
    },
    methods: {
        setForm(){
            if( !this.edit ){
                const {data} = this;
                this.form = data;
                this.form.regionValue = [data.provinceId,data.cityId,data.areaId]
                this.regionValue = [data.provinceId,data.cityId,data.areaId]
            }
        },
        handleChange(arr){
            let {form} = this;
            form.provinceId = arr[0];
            form.cityId = arr[1];
            form.areaId = arr[2]
        },
        getForm(){
            let form;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    form = this.form;
                } else {
                    form = false
                }
            });
            return form;
        },
        empty(){
            this.$refs.form.resetFields();
        }
    }
}
</script>

<style lang="scss" scoped>
    .dialog-info-container {
        padding: 0;
        // background-color: rgba(204,204,204,0.1);
    }
    .form-item-box {
		background: #F7FAFB;
		border-radius: 8px;
		padding: 16px;
        ::v-deep .el-date-editor,
        ::v-deep .el-cascader {
            width: 100% !important;
        }
		.el-row{
			margin:0!important
		}
		.el-col{
			padding: 0!important;
		}
		::v-deep .el-input{			
			min-width: 240px;
			padding: 0;
		}
		::v-deep .w100{
			width: 100%;
		}
		::v-deep .el-form-item__label{
			padding-right: 10px;
		}
		::v-deep .el-textarea__inner{
			border: 1px solid #DCDFE6;
		}
		::v-deep .el-input__inner{
			border: 1px solid #DCDFE6;
		}
    }
    .form-item-box >>> .el-date-editor,
    .form-item-box >>> .el-cascader {
        width: 100% !important;
    }

    .t-form {
        .need-required {
            &::before {
                content: '*';
                position: absolute;
                left: 45px;
                transform: translateY(10px);
                color: #D81414;
                margin-right: 4px;
            }
        }
        .middle-need-required {
            &::before {
                content: '*';
                transform: translateY(10px);
                color: #D81414;
                margin-right: 4px;
            }
        }
    }
</style>